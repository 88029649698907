import React from "react";
import {useTranslation} from "react-i18next";

const AboutUs = ({classicHeader, darkTheme}) => {
    const {t, i18n} = useTranslation();

    return (
        <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* Heading */}
                <div className="position-relative d-flex text-center mb-5">
                    <h2
                        className={
                            "text-24  text-uppercase fw-600 w-100 mb-0 " +
                            (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
                        }
                    >
                        {t("about_me")}
                    </h2>
                    <p
                        className={
                            "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                            (darkTheme ? "text-white" : "text-dark")
                        }
                    >
                        {t("known_me_more")}
                        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
                    </p>
                </div>
                {/* Heading end*/}
                <div className="row gy-5">
                    {/* About me content start */}
                    <div className="col-lg-7 col-xl-8 text-center text-lg-start">
                        <h2 className={"text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")}>
                            {t('about.title1')} <span className="text-primary">Dennis</span> {t('about.title2')}
                        </h2>
                        <p className={darkTheme ? "text-white-50" : ""}>
                            {t('about.part1')}
                        </p>
                        <p className={darkTheme ? "text-white-50" : ""}>
                            {t('about.part2')}
                        </p>
                    </div>
                    {/* About me content end */}
                    {/* about me personal detials start */}
                    <div className="col-lg-5 col-xl-4">
                        <div className="ps-lg-4">
                            <ul className={"list-style-2 " + (darkTheme ? "list-style-light text-light" : "")}>
                                <li>
                                    <span className="fw-600 me-2">{t('about.name_label')}:</span>{t('about.name_value')}
                                </li>
                                <li>
                                    <span className="fw-600 me-2">{t("about.e_mail")}</span>
                                    <a href="mailto:dennis@digital-consult.es">dennis@digital-consult.es</a>
                                </li>
                                <li className="fw-600 me-2">
                                    <span className="fw-600 me-2">{t('about.from_label')}:</span>{t('about.from_value')}
                                </li>
                                <li className="fw-600 me-2 border-0">
                                    <span
                                        className="fw-600 me-2">{t('about.based_in_label')}:</span>{t('about.based_in_value')}
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* about me personal details end */}
                </div>
                {/* projects rewards counting start */}
                <div
                    className={
                        "brands-grid separator-border mt-5 " +
                        (darkTheme ? "separator-border-light" : "")
                    }
                >
                    <div className="row">
                        <div className="col-6 col-md-4">
                            <div className="featured-box text-center">
                                <h4
                                    className={
                                        "text-12  mb-0 " +
                                        (darkTheme ? "text-white-50" : "text-muted")
                                    }
                                >
                                    <span>20</span>+
                                </h4>
                                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                                    {t('about.feature_1')}
                                </p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="featured-box text-center">
                                <h4
                                    className={
                                        "text-12  mb-0 " +
                                        (darkTheme ? "text-white-50" : "text-muted")
                                    }
                                >
                                    <span>16</span>+
                                </h4>
                                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                                    {t('about.feature_2')}
                                </p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="featured-box text-center">
                                <h4
                                    className={
                                        "text-12  mb-0 " +
                                        (darkTheme ? "text-white-50" : "text-muted")
                                    }
                                >
                                    <span>100</span>%
                                </h4>
                                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                                    {t('about.feature_3')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
