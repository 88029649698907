import "./App.scss";
import Header from "./components/Header";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Footer from "./components/Footer";
import React, {useEffect, useState} from "react";
import {commonConfig} from "./config/commonConfig";
import TermsAndConditions from "./components/TermsAndConditions";
import PreLoader from "./components/Preloader";
import {Tooltip} from "./components/Tooltip";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {FloatingWhatsApp} from "react-floating-whatsapp";

function App() {
    const classicHeader = commonConfig.classicHeader;
    const darkTheme = commonConfig.darkTheme;
    const {t, i18n} = useTranslation();

    const handleNavClick = (section) => {
        document.getElementById(section).scrollIntoView({behavior: "smooth"});
    };

    const [scrollTopVisible, setScrollTopVisible] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setisLoading(false);
        }, 1000);
        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);

    const checkScrollTop = () => {
        let scrollTopBtn = document.getElementById("back-to-top");

        if (scrollTopBtn) {
            if (
                document.body.scrollTop > 400 ||
                document.documentElement.scrollTop > 400
            ) {
                setScrollTopVisible(true);
            } else {
                setScrollTopVisible(false);
            }
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", checkScrollTop);
    }

    return (
        <>
            <Helmet>
                <title>{t('page.title')}</title>
            </Helmet>
            <div
                style={{position: "relative"}}
                className={classicHeader ? "" : "side-header"}
            >
                {isLoading && <PreLoader></PreLoader>}

                <div id="main-wrapper">
                    <Header handleNavClick={handleNavClick}></Header>

                    <div id="content" role="main">
                        <AboutUs
                            classicHeader={classicHeader}
                            darkTheme={darkTheme}
                        ></AboutUs>
                        <Services
                            classicHeader={classicHeader}
                            darkTheme={darkTheme}
                        ></Services>
                    </div>
                    <Footer
                        classicHeader={classicHeader}
                        darkTheme={darkTheme}
                        handleNavClick={handleNavClick}
                    ></Footer>
                </div>
                {/* back to top */}
                <Tooltip text={t("back_to_top")} placement="left">
                  <span
                      id="back-to-top"
                      className="rounded-circle"
                      style={{display: scrollTopVisible ? "inline" : "none"}}
                      onClick={() => {
                          window.scrollTo({top: 0, behavior: "smooth"});
                      }}>
                    <i className="fa fa-chevron-up"></i>
                  </span>
                </Tooltip>

                <TermsAndConditions darkTheme={darkTheme}></TermsAndConditions>
                <FloatingWhatsApp
                    phoneNumber="+34662363066"
                    accountName="Dennis Bruecke"
                    avatar="images/digital-consult-canarias.png"
                    chatMessage={t("whatsapp_chat")}
                />
            </div>
        </>
    );
}

export default App;
