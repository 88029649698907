import React from "react";
import {useTranslation} from "react-i18next";

const TermsAndConditions = ({ darkTheme }) => {
  const { t } = useTranslation();
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Aviso legal
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>{t("imprint.p1")}</p>
            <p>{t("imprint.p2")}</p>
            <p>{t("imprint.p3")}</p>
            <h3 className="mb-3 mt-4 text-white">{t("imprint.primera.title")}</h3>
            <p>{t("imprint.primera.p1")}</p>
            <p>{t("imprint.primera.p2")}</p>
            <p>{t("imprint.primera.p3")}</p>
            <p>{t("imprint.primera.p4")}</p>
            <p>{t("imprint.primera.p5")}</p>
            <h3 className="mb-3 mt-4 text-white">{t("imprint.secunda.title")}</h3>
            <p>{t("imprint.secunda.p1")}</p>
            <h3 className="mb-3 mt-4 text-white">{t("imprint.cuarta.title")}</h3>
            <p>{t("imprint.cuarta.p1")}</p>
            <h3 className="mb-3 mt-4 text-white">{t("imprint.quinta.title")}</h3>
            <p>{t("imprint.quinta.p1")}</p>
            <h3 className="mb-3 mt-4 text-white">{t("imprint.sexta.title")}</h3>
            <p>{t("imprint.sexta.p1")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
